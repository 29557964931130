import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Center,
  Square,
  Circle,
  HStack,
  Text,
  useColorMode,
  VStack,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ContactFooter from "../components/ContactFooter";
import Heroblock from "../components/Heroblock";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import ImgMaskReveal from "../DefinitionComponents/Animation-Components/ImgMaskReveal";
import GImage from "../DefinitionComponents/Utility/GImage";

const data = [
  {
    id: 1,
    text: "Abstimmungsübersicht zur Hauptversammlung 2022",
    url: "../files/hv2022/Abstimmung2022.pdf",
  },
  {
    id: 1,
    text: "Einladung zur Hauptversammlung 2022",
    url: "../files/hv2022/220523Einladungstext_virtuelle_HV_2022.pdf",
  },
  {
    id: 2,
    text: "Testatsexemplar der tokentus investment AG für das Geschäftsjahr 2021",
    url: "../files/hv2022/Testatsexemplar-JA 31.12.2021.pdf",
  },
  {
    id: 3,
    text: "Bericht des Aufsichtsrats der tokentus investment AG für das Geschäftsjahr 2021",
    url: "../files/hv2022/220520Bericht des Aufsichtsrats der tokentus investment AG für das Jahr 2021.pdf",
  },
  {
    id: 4,
    text: "Aktuelle Satzung der tokentus investment AG",
    url: "../files/hv2022/211116UR_1123-2021_Satzungsbescheinigung.pdf",
  },
  {
    id: 6,
    text: "Bericht des Vorstands zu Tagesordnungspunkt 5 gemäß §§ 192, 186 Abs. 4 S. 2 AktG",
    url: "../files/hv2022/220520Bericht_des_Vorstandes_TOP_5.pdf",
  },
  {
    id: 8,
    text: "Bericht des Vorstands zu Tagesordnungspunkt 7 gemäß § 203 Abs. 1, Abs. 2 S. 2 i.V.m. § 186 Abs. 4 S. 2 AktGG",
    url: "../files/hv2022/220520Bericht_des_Vorstandes_TOP_7.pdf",
  },
  {
    id: 9,
    text: "Bericht des Vorstands zu Tagesordnungspunkt 8 gemäß § 186 Abs. 4 S. 2 AktG",
    url: "../files/hv2022/220520Bericht_des_Vorstandes_TOP_8.pdf",
  },
  {
    id: 10,
    text: "Vollmacht und Weisungserteilung",
    url: "../files/hv2022/2022_tokentus_eVollmacht.pdf",
  },
  {
    id: 9,
    text: "Informationsblatt zum Online-Service der tokentus investment AG",
    url: "../files/hv2022/2022_tokentus_Informationsblatt_und_Nutzungsbedingungen_Online_Service.pdf",
  },
];

const Team = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("dark");
  });

  return (
    <>
      <Helmet>
        <title>tokentus | Hauptversammlung 2022</title>
      </Helmet>

      <HStack
        bgColor="white"
        justifyContent={"end"}
        h="75vh"
        minH="75vh"
        maxH={{ lg: "95vh", md: "auto" }}
      >
        <Container variant="layoutContainer" py={12} px={{ base: 0, lg: "8" }}>
          <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
            <Flex justifyContent="center" maxW="120px" mx="auto" mt={12}>
              <GImage mb={3} maxW="24px" src="signet_blue.svg" />
            </Flex>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              w={"100%"}
              color="brand.medgray"
              fontWeight="normal"
              fontSize="xl"
              textAlign={"center"}
            >
              Wir laden ein zur
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              w={"100%"}
              color="brand.blue"
              textAlign={"center"}
              fontWeight="normal"
              fontSize={{ base: "4xl", lg: "6xl" }}
            >
              Hauptversammlung 2022.
            </Text>
          </FadeInAnimation>

          <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
            <Heading
              w={"100%"}
              textAlign={"center"}
              fontSize={{ base: "xl", lg: "2xl" }}
              fontWeight="normal"
              mb="8"
              color="brand.medgray"
              maxW={{ base: "80%", lg: "55%" }}
              mx="auto"
            >
              Die Hauptversammlung 2022 der tokentus investment AG fand am
              05.07.2022 um 10:00 Uhr als virtuelle Veranstaltung statt.
            </Heading>
          </FadeInAnimation>
          <Stack
            direction={{ base: "column" }}
            divider={<StackDivider />}
            spacing={4}
            align="stretch"
            justify={"center"}
          >
            <Box>
              <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                <Heading
                  textAlign={"center"}
                  fontSize="2xl"
                  fontWeight="normal"
                  my="4"
                  color="brand.medgray"
                >
                  Hier geht’s zum Online-Service.
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                duration={1}
                delay={0.8}
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Center color="white">
                  <Link
                    m="auto"
                    target="_blank"
                    href="https://hv-ipv.net/tokentus"
                  >
                    <Button
                      display={"inline-flex"}
                      color="brand.white"
                      size={"xl"}
                      margin="0 auto"
                      fontWeight={"normal"}
                      fontSize="xl"
                      px={16}
                      py={6}
                      lineHeight="xl"
                      backgroundColor="brand.blue"
                      leftIcon={
                        <GImage w="24px" h="20px" src="logowhite.svg" />
                      }
                    >
                      tokentus HV 2022
                    </Button>
                  </Link>
                </Center>
              </FadeInAnimation>
            </Box>
          </Stack>
        </Container>
      </HStack>

      <Box bg="brand.white" w={"100%"}>
        <Container
          variant="layoutContainer"
          px={{ base: 0, lg: 10 }}
          py={{ base: 0, lg: 10 }}
        >
          <Flex
            flexWrap="wrap"
            flexDirection="column"
            justifyContent={"space-between"}
          >
            {data.map((data) => (
              <Box w={{ base: "100%", lg: "100%" }} mb={0.5}>
                <Flex
                  wrap="wrap"
                  flexDirection={"row"}
                  alignContent="stretch"
                  alignItems={"stretch"}
                >
                  <HStack
                    h="100%"
                    visibility={{ base: "hidden", lg: "visible" }}
                    display={{ base: "none", md: "flex" }}
                    w="20%"
                    minH="220px"
                    p={0}
                    spacing={0}
                    bg={"brand.blue"}
                  >
                    <ImgMaskReveal
                      imgsrc={"newsthumb2.png"}
                      initScale={1.5}
                      delay={0}
                      duration={2}
                      threshold={0.4}
                      triggerOnce={true}
                    />
                  </HStack>
                  <VStack
                    w={{ base: "100%", lg: "80%" }}
                    px={10}
                    py={10}
                    alignItems={"flex-center"}
                    justifyContent="center"
                    bg={"brand.lightgray"}
                  >
                    <Heading
                      w={{ base: "100%", lg: "70%" }}
                      fontSize="xl"
                      fontWeight="normal"
                      lineHeight={"shorter"}
                      color="brand.meddarkgray"
                    >
                      {data.text}
                    </Heading>
                    <Link href={data.url}>
                      <Button
                        color="white"
                        size={"lg"}
                        fontWeight={"normal"}
                        px={6}
                        backgroundColor="brand.meddarkgray"
                        leftIcon={
                          <GImage
                            w="14px"
                            h="14px"
                            src="download-document.svg"
                          />
                        }
                      >
                        PDF Download
                      </Button>
                    </Link>
                  </VStack>
                </Flex>
              </Box>
            ))}
          </Flex>
        </Container>

        <Container variant={"layoutContainer"} mb={0.5}>
          <ContactFooter
            title="Any questions?"
            subtitle={" This is a special time. For special people."}
            name="Sarah Payne, COO"
            email="sarah.michel@tokentus.com"
            phone="+49 177 24 21 383"
          />
        </Container>
      </Box>
    </>
  );
};

export default Team;
