/* --------------------------------------------------

Component: ImgMaskReveal

Funktion:
  - Löst eine Masken-Animation aus, wenn Block in den Viewport gelangt.

Beispiel:
  <ImgMaskReveal imgsrc={imgsrc} initScale={1} maxH={500} duration={1} delay={1}/>


  TODO: Animation nicht jedes Mal neu auslösen, wenn Element in den Viewport gelangt

----------------------------------------------------- */ 


import { Box } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'
import GImage from '../Utility/GImage'
import InViewTrigger from '../Utility/UX/InViewTrigger'

const MotionBox = motion(Box);

export default function ImgMaskReveal({imgsrc, imgAlt, initScale, maxH, duration, delay, threshold, triggerOnce}) {
  const animation = {
    scale: 1,
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    transition: {
      delay: delay,
      duration: duration,
      ease: [0.2, 0.65, 0.3, 0.9],
    }
  }

  return (
    <InViewTrigger threshold={threshold} triggerOnce={triggerOnce}>
      {(inView) => (
        <Box overflow={'hidden'} display="flex" minH="100%"  alignContent="stretch">
        <MotionBox 
          initial={{
            scale: initScale,
            clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)', 
          }}
          animate={inView ? animation : {}}
        >
        <GImage
          objectFit={'cover'}
          maxH={maxH}
          w={'100%'}
          src={imgsrc}
          alt={imgAlt}
        />
        </MotionBox>
        </Box>
      )}
    </InViewTrigger>
  )
}
